import { contactSubmit } from './contact.js';
import { initMultiMap } from './multiMap';

if (document.getElementById('contact_form')) {
	contactSubmit({
		required: ['name', 'email', 'facility', 'message'],
	});
}

window.showPayOverlay = () => {
	const overlay = document.querySelector('#bill_overlay');
	overlay.classList.add('active');
	overlay.addEventListener('click', e => {
		if(e.target.id === 'bill_overlay') {
			overlay.classList.remove('active');
		}
	});
	// document.documentElement.classList.remove("js-nav");
	// app.closeNav();
};

// Home Map
if(document.querySelector('#home_map')) {
	initMultiMap();
}

// Facility Accordion Menu
if (document.getElementById('facility_accordion_items_wrap')) {
	const helpItems = document.querySelectorAll('.facility_info_section');
	document.getElementById('facility_accordion_items_wrap').addEventListener('click', function(e) {
		if(e.target && e.target.nodeName === "H2") {
			let el = e.target.parentElement;
			if(el.classList.contains('active')) {
				el.classList.remove('active');
			}
			else {
				helpItems.forEach(i => {
					i.classList.remove('active');
				});
				el.classList.add('active');
			}
		}
	});
}