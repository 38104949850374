import { getMapsHelper } from './util/mapsHelper';

export const initMultiMap = () => {
	const mapHelper = getMapsHelper();

	mapHelper.ready()
		.then(() => {
			mapHelper.createMap({
				element: document.querySelector('#home_map'),
				locationElementSelector: '.data_source',
				useRichmarker: true,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `
							<div class="map_pin">
								<img src="/static/images/ic-pin.svg" alt="Visit facility" />
							</div>
						`
					};
				},
			});
		});
};